import { Avatar, Button, Checkbox, Popover } from 'antd';
import StatusPopoverComponent from './StatusPopoverComponent';
import { InfoCircleOutlined, MoreOutlined, ProfileOutlined, RightOutlined } from '@ant-design/icons';
import { BASE_IMG } from '../../constants';
import { capitalizeFirstLetter, formatToCustomTime, getStatusStyle } from '../../utils';
import { useState } from 'react';

export default function ListInfoComponent({ name, id, item, AvatarColor, badges, popoverOnboarding, setPopoverOnboarding, type, vendorSelections, selectAll, pagination, handleSelectToggle, handleIconClick, handleBoxClick, handleApproveQuote }) {
    const [showPopup, setShowPopup] = useState(null);
    const handleTrippleDot = (item, visible) => {
        if (visible) {
            setShowPopup(item);
        } else {
            setShowPopup(null);
        }
    };

    const handleOptionClick = async(item, option) => {
        // if(option.linkURL == 'delete'){
        //   const response = await deleteQuoteRelation(item._id);
        //   if (response) {
        //     setVendorData((prevData) => prevData.filter((vendor) => vendor._id !== item._id));
        //     showSuccessMessage('Vendor removed successfully');
        //   } else {
        //     showErrorMessage('something went wrong');
        //   }
        // }
        // else if(option.linkURL == 'history'){
        //   setShowModalHistory(true)
        //   setActiveItem(item)
        // }
        setShowPopup(null);
      }
    const getBadgeImage = (color) => {
        switch (color) {
            case 'green':
                return `${BASE_IMG}/verified.svg`;
            case 'red':
                return `${BASE_IMG}/error.svg`;
            case 'yellow':
                return `${BASE_IMG}/pending.svg`;  
            case 'blue':
                return `${BASE_IMG}/info.svg`;
            case 'purple':
                return `${BASE_IMG}/approved.svg`;
            case 'orange':
                return `${BASE_IMG}/verification.svg`;      
            default:
                return null;
        }
    };
    const handleClick =() =>{
        if (type === 'ListQuotes') {
          return () => handleBoxClick(id, item.status === 'draft' ? 'seeFormDraft' : 'seeForm');
        } else if (type === 'ListApprovalsAdmin') {
          return () => handleApproveQuote(item, 'admin');
        } else {
          return null;
        }
      }

    return (
        <div className="ListComponent_box2" onClick={handleClick()} style={(type === 'ListQuotes' || type == 'ListApprovalsAdmin') ? {cursor: 'pointer'}: {}}>
            <div className="ListComponent_box1" style={{minWidth: '296px'}} >
                {type == 'VendorList' && pagination.total > 1 &&
                 <Checkbox
                        checked={(vendorSelections? vendorSelections[id]: false) || selectAll}
                        onChange={() => handleSelectToggle(id)}
                    />
                }
                <Avatar style={AvatarColor?.[0]?.style}>
                    {(type === 'VendorList' ||  type === 'ListApprovals' || type === 'ListApprovalsAdmin') && name.charAt(0)}
                    {type == 'ListQuotes' && <ProfileOutlined />}
                </Avatar>
                <div className="ListComponent_qt">
                    <h3>{name && name.length > 20 ? `${name.slice(0, 20)}...` : name}</h3>
                    {type == 'ListQuotes' && <span>{id}</span>}
                    {(type === 'ListApprovals' || type === 'ListApprovalsAdmin') && <span>{item.userMeta.email}</span>}
                </div>
                {badges && badges.map((badge) => (
                    <StatusPopoverComponent
                        key={badge.key}
                        image={getBadgeImage(badge.color)}
                        popoverContent={
                            <div className='ListComponent_tick'>
                                <InfoCircleOutlined />
                                <div className='ListComponent_tickDetail'>
                                    {badge.description}
                                    {badge.action.text && <Button>{badge.action.text}</Button>}
                                </div>
                            </div>
                        }
                        popoverVisible={popoverOnboarding === id}
                        setPopoverVisible={() => setPopoverOnboarding(popoverOnboarding ? null : id)}
                        className={badge.displayType}
                    />
                ))}
            </div>
            {type == 'ListQuotes' && (
                    <>
                       <p style={{ width: "205px" }}>
                        {item.itemNames && item.itemNames.length > 0 
                            ? item.itemNames.join(", ") 
                            : "No items available"}
                        </p>
                        {item.vendorNames?.length > 0 ? (
                            <p style={{ width: '259px' }}>{item.vendorNames.join(', ')}</p>
                        ) : (
                            <span style={{ width: '259px', color: 'rgba(0, 0, 0, 0.25)' }}>No vendors added</span>
                        )}
                    </>
                    )}
                {(type == 'ListApprovals' || type== 'ListApprovalsAdmin') && (
                     <>
                      <div className="ListComponent_qt" style={{alignItems: 'center'}}>
                        <p style={{ width: "205px" }}>{item.requestMeta?.latestComment}</p>
                        {item.requestMeta?.quoteId ? <span>Quotation Id {item.requestMeta?.quoteId}</span>
                        : <span>For brands: {item.requestMeta?.name}</span>}

                     </div>
                     {item?.updatedAt ? (
                        <p style={{width: '158px'}}>
                            {formatToCustomTime(item.updatedAt)}
                        </p>
                    ) : item.createdAt ? (
                        <p>
                            {formatToCustomTime(item.createdAt)}
                        </p>
                    ) : (
                        ""
                        )}
                        </>
                    )}    
                        <div className="ListCompoenent_qs1" style={{ width: "159px" }}>
                            <div className="ListComponent_qs">
                                <i style={getStatusStyle(item?.status  || item?.finalStatus)} />
                                <span>{capitalizeFirstLetter(item?.status || item?.finalStatus)}</span>
                            </div>
                               {type === 'ListApprovals' && item?.urgent && <span className='ListComponent_reminder'>Urgent</span>}
                               {type === 'ListApprovals' && item?.subStatus  && <span className='ListComponent_reminder_partial'>{capitalizeFirstLetter(item.subStatus)}</span>}
                        </div>
    
                        <div className="ListComponent_box1">
                            {type == 'ListQuotes' && (
                                item?.requestedOn ? (
                                    <p>
                                    {formatToCustomTime(item.requestedOn)}
                                    </p>
                                ) : (
                                    <p style={{padding: '0 70px'}}>NA</p> 
                                )
                            )}
                            {type == 'ListApprovals' && (
                                <>
                                <span className='approvals_action' onClick={()=> handleApproveQuote(item, 'approve')}>Approve</span>
                                <span className='approvals_action' onClick={()=> handleApproveQuote(item, 'reject')}>Reject</span>
                                </>
                            )}
                            {type === 'ListApprovalsAdmin' && (
                                <>
                                    <span className='approvals_action' onClick={()=> handleApproveQuote(item, 'admin')}>Add comments</span>
                                    {
                                        item.options && item.options.tripledots?.length > 0 && (
                                  
                                    <Popover
                                        content={
                                        <div className="list_popup">
                                            {item.options && item.options.tripledots?.map((option) => (
                                                <span key={option.key} onClick={() => handleOptionClick(item, option)}>
                                                    {option.label}
                                                </span>
                                            ))}
                                        </div>
                                        }
                                        overlayClassName="list_popover"
                                        trigger="click"
                                        placement="bottomLeft"
                                        open={showPopup === item}
                                        onOpenChange={(visible) => handleTrippleDot(item, visible)}>
                                            <MoreOutlined className="ListComponent_moreIcon"/>
                                    </Popover>)}
                                </>
                            )}
                            {type !== 'VendorList' && type!== 'ListApprovalsAdmin' &&  <div className="ListComponent_qa" onClick={() => handleIconClick(type === 'ListApprovals' ? item.requestMeta?.quoteId : item.quoteId, item, 'ListApprovals')}><RightOutlined /></div>}
                        </div>
        </div>
    );
};
