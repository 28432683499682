import React, { useState , useEffect} from 'react';
import './style.css';
import BulkUploadComponent from './BulkUploadComponent';
import AddManualComponent from './AddManualComponent';
import StepByStepAdditionComponent from './StepByStepAdditionComponent';
import { ArrowLeftOutlined } from '@ant-design/icons';
import RegistrationComponent from './RegistrationComponent';
import BreadcrumbComponent from '../../common/BreadcrumbComponent';
import { ERROR_CTA_MAP } from '../../../constants';
import { downloadCSVFile } from '../../../utils';
import { fetchData } from '../../../services/HomeService';
import { Switch } from 'antd';

export default function VendorOnboard({ selectedParent, selectedChild }) {
    const [showStepByStep, setShowStepByStep] = useState(false);
    const [isCSVUploaded, setIsCSVUploaded] = useState(false);
    const [csvData, setCSVData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isBulkUpload, setIsBulkUpload] = useState(false);
    const [formNames, setFormNames] = useState([]);
    const [selectedFormName, setSelectedFormName] = useState('');
    const [isSwitchChecked, setIsSwitchChecked] = useState();
    useEffect(() => {
        const fetchFormNames = async () => {
            const response = await fetchData('/partners/register/vendor/getalltemplates');
            if(response.success){
                const data = response.data;
                const formNamesArray = Object.keys(data).map(key => ({
                    value: key,
                    label: data[key].label,
                    fields: data[key].fields,
                    type: data[key].type
                }));
                console.log('formNamesArray', formNamesArray);
                setFormNames(formNamesArray);
                const defaultForm = formNamesArray.find(form => form.type == 'default');
                if (defaultForm) {
                    setSelectedFormName(defaultForm.value);
                    setIsSwitchChecked(formNamesArray.indexOf(defaultForm) == 1);
                    setResponseData(defaultForm.fields);
                }
            }
        }
        fetchFormNames();
    }, []);

    const handleStepByStepClick = () => {
        setShowStepByStep(true);
    };

    const handleManualUpload = () => {
        setIsCSVUploaded(true);
    };

    const handleCSVUpload = (data) => {
        setIsCSVUploaded(true);
        setIsBulkUpload(true);
        setCSVData(data);
    };

    const handleBackClick = () => {
        setVisible(!(showStepByStep || isCSVUploaded));
        setIsCSVUploaded(false);
        setShowStepByStep(false);
    };

    let additionalText = '';
    if (isCSVUploaded) {
        additionalText = 'Entries';
    } else if (showStepByStep) {
        additionalText = 'How to add a list';
    }
    const handleSwitchChange = (checked) => {
        setIsSwitchChecked(checked);
        const selectedForm = formNames[checked ? 1 : 0]; // Assuming the two forms are the first two in the array
        console.log('selectedForm', selectedForm);
        setSelectedFormName(selectedForm.value);
        setResponseData(selectedForm.fields);
    }

       const handleDownloadClick = async () => {
        try {
            const response = await fetchData('/partners/register/vendor?formname=' + selectedFormName);
            if(response.success){
                setResponseData(response.data);
                const fileName = 'vendor_onboarding_template.csv';
                const dataKeys = Object.keys(response.data);
                const formattedData = [Object.fromEntries(dataKeys.map(key => [key, '']))];
                const success = await downloadCSVFile(formattedData, fileName);
                return { success, response };
            }
        } catch (error) {
            console.log('Download Error:', error);
            return false;
        }
    }

    const handleFileUpload = (file) => {
        if (!file) {
            return { success: false, errorMessage: ERROR_CTA_MAP['FILE_UPLOAD_ERROR'] };
        }
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        if (fileExtension !== 'csv') {
            return { success: false, errorMessage: ERROR_CTA_MAP['FILE_TYPE_ERROR'] };
        }

        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (e) => {
                let csvData = e.target.result;
                resolve({ success: true, csvData });
            };
            reader.readAsText(file);
        });
    }

    return (
        <main className='Home_container'>
            <section className='Home_firstContainer'>
                <BreadcrumbComponent selectedParent={selectedParent} selectedChild={selectedChild} additionalText={additionalText} />
                <div className='Home_heading'>
                    {isCSVUploaded ? (
                        <>
                            <ArrowLeftOutlined className='VendorOnboarding_arrowBack' onClick={handleBackClick} />
                            <h1>Enter Details</h1>
                        </>
                    ) : showStepByStep ? (
                        <>
                            <ArrowLeftOutlined className='VendorOnboarding_arrowBack' onClick={handleBackClick} />
                            <h1>Steps to Add in Bulk</h1>
                        </>
                    ) : (
                        <h1>Add New Vendor</h1>
                    )}
                </div>
            </section>
            {!isCSVUploaded && (
            <div className='Toggler_main' style={{margin: "24px 24px 0px 24px"}}>
                <div className='Toggler_containerLeft'>
                    {formNames.length >= 2 && (
                        <div>
                            <span>{formNames[0].label}</span>
                            <Switch
                                checked={isSwitchChecked}
                                onChange={handleSwitchChange}
                                style={{backgroundColor: isSwitchChecked ? '#5121fd' : '#5121fd'}}
                            />
                            <span style={{padding: "5px"}}>{formNames[1].label}</span>
                        </div>
                    )}
                </div>
            </div>)}
            { selectedFormName && selectedFormName == 'invitation' ?
            <div className='Toggler_main_form' style={{margin: "24px 24px 0px 24px"}}>
            <RegistrationComponent prefillmap={csvData} template={responseData} registerType={'vendor'} isBulkUpload={isBulkUpload} formname={selectedFormName} minimal={true}/>
            </div> :

            
            <section className="VendorOnboard_adding">
                {isCSVUploaded ? (
                    <RegistrationComponent prefillmap={csvData} template={responseData} registerType={'vendor'} isBulkUpload={isBulkUpload} formname={selectedFormName}/>
                ) : showStepByStep ? (
                    <StepByStepAdditionComponent onBackClick={handleBackClick} onCSVUpload={handleCSVUpload} handleDownloadClick={handleDownloadClick} handleFileUpload={handleFileUpload} />
                ) : (
                    <>
                        <BulkUploadComponent visible={visible} setVisible={setVisible} onCSVUpload={handleCSVUpload} onStepByStepClick={handleStepByStepClick} handleDownloadClick={handleDownloadClick} handleFileUpload={handleFileUpload} />
                        <AddManualComponent onManualUpload={handleManualUpload} />
                    </>
                )}
            </section>
}
        </main>
    );
}
